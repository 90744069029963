
export default {
  name: 'MobileDropdown',
  props: {
    iconPosition: {
      type: String,
      default: 'top',
    },
  },
  data() {
    return {
      expanded: false,
    }
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded
      this.$emit('clicked')
    },
  },
}
