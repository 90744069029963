
import VClamp from 'vue-clamp'
import { getDateDistance } from '~/utils'
export default {
  name: 'ImageArticleCard',
  components: {
    'v-clamp': VClamp,
  },
  props: {
    content: {
      type: Object,
      default: () => ({
        title: '',
        date: 'Just now',
        description: '',
      }),
    },
  },
  computed: {
    url() {
      return this.content?.current_url?.full_url || '/newsroom'
    },
    formattedDate() {
      return getDateDistance(
        this.content.date,
        this.$store.state['date-fns'].activeLocale,
      )
    },
    parsedHash() {
      return '#' + this.content.hashTags.join(', #')
    },
  },
}
