
import { getEmbeddedUrl } from '~/utils/youtubeHelpers'
export default {
  name: 'ModalVideo',
  props: {
    src: {
      type: String,
      default: '',
    },
  },
  methods: {
    getEmbeddedUrl,
  },
}
