
import { mapMutations } from 'vuex'
import { newsPosts } from '@/queries/news/newsPosts.gql'
import { stripHtml, formatRawMapResponse } from '@/utils'
import { ContactsQuery } from '@/queries/contacts.gql'

const THEMES = {
  TRANSPARENT: 'transparent',
  LIGHT: 'light',
}

export default {
  name: 'IotLanding',
  components: {
    MobileDropdown: () => import('@/components/MobileDropdown.vue'),
  },
  filters: {
    stripHtml,
  },
  async asyncData({ app, i18n, $config, $sentry }) {
    try {
      const { data } = await app.$gqlQuery({
        query: newsPosts,
        fetchPolicy: 'no-cache',
        variables: {
          languageCode: i18n.locale,
          domainId: $config.domainId,
          postsLimit: 5,
        },
      })
      return {
        corporateNews: data?.newsPosts?.data || [],
      }
    } catch (error) {
      $sentry.captureException(error)
    }
  },
  data() {
    return {
      countries: false,
      videoSrc: null,
      subHover: false,
      activeHover: null,
      active: 'iot',
      corporateNews: [],
      pageFiles: {},
    }
  },
  computed: {
    branches() {
      return this.groups.slice(1, this.groups.length)
    },
    activeBranch() {
      if (this.activeHover !== null)
        return this.groups.find((item) => item.id === this.activeHover)
      return this.groups.find((item) => item.id === this.active)
    },
    currentTheme() {
      return this.activeBranch.slides[this.activeBranch.activeSlide].theme
    },
    careerImages() {
      return [
        this.pageFiles['file.iot.landing.career.image1'],
        this.pageFiles['file.iot.landing.career.image2'],
        this.pageFiles['file.iot.landing.career.image3'],
      ]
    },
    allImages() {
      const mediaList = []
      this.groups.forEach((branch) => {
        branch?.slides?.forEach((item) => {
          mediaList.push({
            id: `${branch.id}-${item.id}`,
            ...item.media,
          })
        })
      })
      return mediaList
    },
    groups() {
      return [
        {
          id: 'iot',
          navigationTitle: 'front.iot_lander.hero.slider_1.navigation_title',
          activeSlide: 0,
          slides: [
            {
              id: 1,
              title: 'front.iot_lander.hero.slider_1.title',
              badge: 'front.iot_lander.hero.slider_1.badge',
              media: {
                image: this.pageFiles['file.iot.landing.hero.iot'],
                video: 'front.iot_lander.hero.slider_2.video_link',
                type: 'bg-video',
                load: true,
              },
              theme: THEMES.TRANSPARENT,
              content: 'front.iot_lander.hero.slider_1.description',
              href: '#',
            },
          ],
        },
        {
          id: 'telematics',
          navigationTitle: 'front.iot_lander.hero.slider_2.navigation_title',
          activeSlide: 0,
          slides: [
            {
              id: 1,
              title: 'front.iot_lander.hero.slider_2.title',
              badge: 'front.iot_lander.hero.slider_2.badge',
              media: {
                image: this.pageFiles['file.iot.landing.hero.telematics'],
                type: 'img',
                load: false,
              },
              theme: THEMES.TRANSPARENT,
              content: 'front.iot_lander.hero.slider_2.description',
              href: 'https://teltonika-gps.com',
            },
          ],
        },
        {
          id: 'networks',
          navigationTitle: 'front.iot_lander.hero.slider_3.navigation_title',
          activeSlide: 0,
          slides: [
            {
              id: 1,
              title: 'front.iot_lander.hero.slider_3.title',
              badge: 'front.iot_lander.hero.slider_3.badge',
              media: {
                image: this.pageFiles['file.iot.landing.hero.networks'],
                type: 'img',
                load: false,
              },
              theme: THEMES.TRANSPARENT,
              content: 'front.iot_lander.hero.slider_3.description',
              href: 'https://teltonika-networks.com',
            },
          ],
        },
        {
          id: 'telemedic',
          navigationTitle: 'front.iot_lander.hero.slider_4.navigation_title',
          activeSlide: 0,
          slides: [
            {
              id: 1,
              title: 'front.iot_lander.hero.slider_4.title',
              badge: 'front.iot_lander.hero.slider_4.badge',
              media: {
                image: this.pageFiles['file.iot.landing.hero.telemedic'],
                type: 'img',
                load: false,
              },
              theme: THEMES.TRANSPARENT,
              content: 'front.iot_lander.hero.slider_4.description',
              href: 'https://teltonika-telemedic.com',
            },
          ],
        },
        {
          id: 'ems',
          navigationTitle: 'front.iot_lander.hero.slider_5.navigation_title',
          activeSlide: 0,
          slides: [
            {
              id: 1,
              title: 'front.iot_lander.hero.slider_5.title',
              badge: 'front.iot_lander.hero.slider_5.badge',
              media: {
                image: this.pageFiles['file.iot.landing.hero.ems'],
                type: 'img',
                load: false,
              },
              theme: THEMES.TRANSPARENT,
              content: 'front.iot_lander.hero.slider_5.description',
              href: 'https://teltonika-ems.com',
            },
          ],
        },
        {
          id: 'tech',
          navigationTitle: 'front.iot_lander.hero.slider_6.navigation_title',
          activeSlide: 0,
          slides: [
            {
              id: 1,
              title: 'front.iot_lander.hero.slider_6.title',
              badge: 'front.iot_lander.hero.slider_6.badge',
              media: {
                image: this.pageFiles['file.iot.landing.hero.thth'],
                type: 'img',
                load: false,
              },
              theme: THEMES.TRANSPARENT,
              content: 'front.iot_lander.hero.slider_6.description',
              href: 'https://teltonika-high-tech-hill.com',
            },
          ],
        },
        {
          id: 'energy',
          navigationTitle: 'front.iot_lander.hero.slider_7.navigation_title',
          activeSlide: 0,
          slides: [
            {
              id: 1,
              title: 'front.iot_lander.hero.slider_7.title',
              badge: 'front.iot_lander.hero.slider_7.badge',
              media: {
                image: this.pageFiles['file.iot.landing.hero.energy'],
                type: 'img',
                load: false,
              },
              theme: THEMES.TRANSPARENT,
              content: 'front.iot_lander.hero.slider_7.description',
              href: 'https://teltonika-energy.com',
            },
          ],
        },
      ]
    },
  },
  created() {
    this.pageFiles = this.$store.state.page.content.files || {}
  },
  methods: {
    ...mapMutations({
      openFormModal: 'formModal/setFormCode',
    }),
    async loadMap() {
      const {
        data: { contacts },
      } = await this.$gqlQuery({
        query: ContactsQuery,
        fetchPolicy: 'no-cache',
        variables: {
          domainId: this.$config.domainId,
          languageCode: this.$i18n.locale,
        },
      })
      const formattedCountries = formatRawMapResponse(contacts)
      this.countries = formattedCountries

      if (!Array.isArray(formattedCountries) && formattedCountries.length === 0)
        return

      this.$store.commit(
        'contacts/setOffices',
        formattedCountries
          .flatMap((countryItem) => countryItem.offices)
          .map((countryItem) => ({
            id: countryItem.id,
            title: countryItem.title_translated,
            address: countryItem.address_translated,
            value: countryItem.value,
          })),
      )
    },
    onSelect(branch) {
      if (branch.disabled) return
      if (branch.id !== 'iot') {
        window.location.href = branch?.slides?.[0]?.href || '#'
      } else {
        this.active = branch.id
      }
    },
    onLeave() {
      this.activeHover = null
    },
    onEnter(branch) {
      this.activeHover = branch.id
    },
    goPrev() {
      const activeBranch = this.getReactiveBranch()
      if (activeBranch.activeSlide > 0) {
        activeBranch.activeSlide = activeBranch.activeSlide - 1
      }
    },
    goNext() {
      const activeBranch = this.getReactiveBranch()
      if (activeBranch.activeSlide < activeBranch.slides.length - 1)
        activeBranch.activeSlide = activeBranch.activeSlide + 1
    },
    setSlide(index) {
      const activeBranch = this.getReactiveBranch()
      activeBranch.activeSlide = index
    },
    getReactiveBranch() {
      return this.groups.find(
        (item) => item.id === this.active || item.id === this.activeHover,
      )
    },
    rendered(height) {
      const active = this.getReactiveBranch()
      this.$set(active, 'height', height)
    },
    getButtonText(item) {
      let key = 'visit_our_page'
      if (item.href === '#' && this.activeBranch.activeSlide === 0) {
        key = 'contact_us'
      } else if (this.activeBranch.activeSlide > 0) {
        key = 'front.news.read_more'
      }
      return this.$t(key)
    },
    loadNext(index) {
      if (this.groups?.[index + 1])
        this.groups[index + 1].slides[0].media.load = true
    },
  },
}
