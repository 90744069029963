import { render, staticRenderFns } from "./teltonika-iot-group.com-landing.vue?vue&type=template&id=6a0e08b6"
import script from "./teltonika-iot-group.com-landing.vue?vue&type=script&lang=js"
export * from "./teltonika-iot-group.com-landing.vue?vue&type=script&lang=js"
import style0 from "./teltonika-iot-group.com-landing.vue?vue&type=style&index=0&id=6a0e08b6&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prettier@3.2.5_vue-temp_n4gdrmfirpmgaryqotnkfbokze/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalVideo: require('/src/components/modal/Video.vue').default,DsBaseHeading: require('/src/components/ds/base/Heading.vue').default,Button: require('/src/components/Button.vue').default,HeroSlideContent: require('/src/components/hero/SlideContent.vue').default,MobileDropdown: require('/src/components/MobileDropdown.vue').default,CarouselEight: require('/src/components/carousel/CarouselEight.vue').default,CarouselCorporateNews: require('/src/components/carousel/CorporateNews.vue').default,InfinityLoader: require('/src/components/InfinityLoader.vue').default})
