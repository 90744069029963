
import { VueAgile } from 'vue-agile'

export default {
  name: 'CorporateNews',
  components: {
    VueAgile,
  },
  props: {
    corporateNews: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      slideOptions: {
        navButtons: false,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 12000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              navButtons: true,
            },
          },
        ],
      },
    }
  },
}
