
import { VueAgile } from 'vue-agile'
export default {
  name: 'CarouselEight',
  components: {
    VueAgile,
  },
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      default: '',
    },
  },
}
